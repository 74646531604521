import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Link } from 'react-router-dom';
import "@fontsource/dotgothic16";
import Login from './components/Login';
import CreateAgent from './components/CreateAgent';
import ListAgents from './components/ListAgents';
import Chat from './components/Chat';
import Profile from './components/Profile';
import NavigationLinks from './components/NavigationLinks';
import UserInfo from './components/UserInfo';
import './App.css';
import logo from './assets/logo.png';
import AgentTransaction from './components/AgentTransaction';
import Register from './components/Register';
import ConclusionInvest from './components/ConclusionInvest';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [did, setDid] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedDid = localStorage.getItem('did');
    if (storedUsername) {
      setUsername(storedUsername);
    }
    if (storedDid) {
      setDid(storedDid);
    }
  }, []);
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('did');
    setUsername('');
    setDid('');
    navigate('/login');
  };

  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="App">
      {!isAuthPage && (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="Logo" height="30" /> CTRL+V Demo
            </Link>
            <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
              <div className="navbar-nav">
                <NavigationLinks />
              </div>
              <div className="navbar-nav ms-auto">
                <UserInfo username={username} onLogout={handleLogout} />
              </div>
            </div>
          </div>
        </nav>
      )}
      <Routes>
        <Route path="/" element={<ListAgents onLogout={handleLogout} />} />
        <Route path="/register" element={<Register setUsername={setUsername} setDid={setDid} />} />
        <Route path="/login" element={<Login setUsername={setUsername} setDid={setDid} />} />
        <Route path="/create-agent" element={<CreateAgent onLogout={handleLogout} />} />
        <Route path="/list-agents" element={<ListAgents onLogout={handleLogout} />} />
        <Route path="/transactions" element={<AgentTransaction onLogout={handleLogout} />} />
        <Route path="/chat/:agentId" element={<Chat onLogout={handleLogout} setDid={setDid} />} />
        <Route path='/conclusion-invest' element={<ConclusionInvest></ConclusionInvest>} onLogout={handleLogout} />
        <Route path="/profile" element={<Profile username={username} did={did} setUsername={setUsername} setDid={setDid} onLogout={handleLogout} />} />
      </Routes>

    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
